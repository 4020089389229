/**
 * FacilityHierarchyReview
 * This is the API for working with the Facility Hierarchy Review Application
 *
 * OpenAPI spec version: 1.0.0
 * Contact: jkerski@definitivelogic.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import axios, { AxiosResponse, AxiosStatic } from 'axios';

import { Configuration } from 'mahso-common/services/authorization/configuration';
import { UserInfo } from 'mahso-common/services/types';

import RelativeURL from './relativeURL';

let BASE_PATH: string;
BASE_PATH =
    `${process.env.REACT_APP_FACILITY_REVIEW_HIERARCHY_API}/mahso/facilityhierarchyreview/v1`.replace(
        /\/+$/,
        ''
    );
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|'
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<AxiosResponse> | Promise<Response>;
}

export type FetchAPIType = FetchAPI | AxiosStatic;

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration!: Configuration;

    constructor(
        configuration?: Configuration,
        protected basePath: string = BASE_PATH,
        protected fetch: FetchAPIType = axios
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: 'RequiredError';

    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/** UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return User
         * @summary Gets the current User
         * @param {object} UserInfo the current user
         */
        getUser(user: UserInfo, options: any = {}): FetchArgs {
            const localVarPath = `/user/GetUser`;
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'GET', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('user_auth', ['write:user', 'read:user'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;

            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        }
    };
};
export const UserApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Returns the User
         * @summary Get the current user for conducting data analysis
         * @param {object} user the current user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(
            user: UserInfo,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<UserInfo> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUser(
                user,
                options
            );
            return (fetch: FetchAPIType = axios, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
                    (response) => {
                        if (response.status >= 200 && response.status < 300) {
                            var anyResponse = response as any;
                            return anyResponse.data as unknown as UserInfo;
                        } else {
                            throw response;
                        }
                    }
                );
            };
        }
    };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPIType,
    basePath?: string
) {
    return {
        /**
         * Returns User
         * @summary Get User for conducting a data analysis
         * @param {object} user The current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(user: UserInfo, options?: any) {
            return UserApiFp(configuration).getUser(user, options)(fetch, basePath);
        }
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Returns Users
     * @summary Get the user for conducting a data analysis
     * @param {object} user The current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(user: UserInfo, options?: any) {
        return UserApiFp(this.configuration).getUser(user, options)(this.fetch, this.basePath);
    }
}
